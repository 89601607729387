<script setup lang="ts">
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";

import { Dialog, DialogPanel, PopoverGroup } from "@headlessui/vue";
import Avatar from "../Avatar.vue";
import RedesignNavBarPopover from "./RedesignNavBarPopover.vue";
import UserMenu from "./UserMenu.vue";

const route = useRoute();

const aboutHighlighted = computed(() => {
    return route.path.startsWith("/about");
});

const solutionsHighlighted = computed(() => {
    return route.path.startsWith("/solutions");
});

const contactHighlighted = computed(() => {
    return route.path.startsWith("/contact");
});

const landingPageSettings = useLandingPageSettings();

const about = [
    {
        name: "Company",
        href: "/about",
        ready: true,
    },
    {
        name: "Insights & Foresights Blog",
        href: "/about/blog",
        ready: true,
    },
];

const callsToAction = [
    {
        name: "See a demo",
        href: computed(() => landingPageSettings.value?.livePreviewUrl ?? "#"),
    },
    {
        name: "Sign up",
        href: computed(() => "/solutions/bill-boards/request"),
    },
];

const userNavigation = [
    {
        name: "Bill Boards",
        href: "/app/boards",
    },
    {
        name: "Log out",
        href: "/logout",
    },
];

const mobileMenuOpen = ref(false);

const civiqaUser = useCiviqaUser();
console.log(civiqaUser.value);
const showUserMenu = computed(
    () => civiqaUser.value && !civiqaUser.value.isFirebaseAnonymous,
);

const showLogin = computed(() => {
    return (
        !showUserMenu.value &&
        route.name !== "login" &&
        route.name !== "usermgmt"
    );
});

const showSignup = computed(() => {
    return (
        !showUserMenu.value &&
        route.name !== "signup" &&
        route.name !== "usermgmt"
    );
});
</script>

<template>
    <header class="bg-white">
        <nav
            class="relative mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
            aria-label="Global"
        >
            <div class="flexflex-1">
                <div class="shrink-0">
                    <NuxtLink to="/home">
                        <img
                            class="h-8 w-auto"
                            src="/horizontal.svg"
                            alt="Civiqa"
                        />
                    </NuxtLink>
                </div>
            </div>

            <div class="flex md:hidden">
                <button
                    type="button"
                    class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    @click="mobileMenuOpen = true"
                >
                    <span class="sr-only">Open main menu</span>

                    <Bars3Icon class="size-6" aria-hidden="true" />
                </button>
            </div>

            <PopoverGroup
                class="justify-center absolute left-1/2 right-1/2 hidden md:flex md:items-center md:gap-x-12"
            >
                <RedesignNavBarPopover
                    title="Solutions"
                    :items="solutions"
                    href="/solutions"
                    :highlighted="solutionsHighlighted"
                />

                <RedesignNavBarPopover
                    title="About"
                    :items="about"
                    href="/about"
                    :highlighted="aboutHighlighted"
                />

                <NuxtLink
                    :to="`/contact?redirect=${route.fullPath}`"
                    class="text-sm font-semibold leading-6"
                    :class="[
                        contactHighlighted ? 'text-green-600' : 'text-gray-900',
                    ]"
                >
                    Contact
                </NuxtLink>
            </PopoverGroup>

            <div class="hidden md:block">
                <div class="ml-4 flex items-center md:ml-6">
                    <div v-if="showUserMenu">
                        <UserMenu />
                    </div>
                    <div v-else class="flex items-center gap-x-2">
                        <NuxtLink
                            v-if="showSignup"
                            href="/solutions/bill-boards/request"
                            class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            Sign up
                        </NuxtLink>

                        <NuxtLink
                            v-if="showLogin"
                            href="/login"
                            class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-inset ring-gray-300 hover:text-green-700"
                        >
                            Log in
                            <span aria-hidden="true">&rarr;</span>
                        </NuxtLink>
                    </div>
                </div>
            </div>
        </nav>

        <Dialog
            as="div"
            class="lg:hidden"
            :open="mobileMenuOpen"
            @close="mobileMenuOpen = false"
        >
            <div class="fixed inset-0 z-10" />

            <DialogPanel
                class="fixed inset-y-0 right-0 z-10 flex w-full flex-col justify-between overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
            >
                <div class="p-6">
                    <div class="flex items-center justify-between">
                        <NuxtLink to="/">
                            <img
                                class="h-8 w-auto"
                                src="/horizontal.svg"
                                alt="Civiqa"
                                @click="mobileMenuOpen = false"
                            />
                        </NuxtLink>

                        <button
                            type="button"
                            class="-m-2.5 rounded-md p-2.5 text-gray-700"
                            @click="mobileMenuOpen = false"
                        >
                            <span class="sr-only">Close menu</span>

                            <XMarkIcon class="size-6" aria-hidden="true" />
                        </button>
                    </div>

                    <div class="mt-10 flow-root">
                        <div class="space-y-4">
                            <div class="space-y-2">
                                <NuxtLink
                                    href="/about"
                                    class="-mx-3 block rounded-sm px-3 py-2 text-base font-bold leading-7 text-gray-900 hover:bg-green-50"
                                    @click="mobileMenuOpen = false"
                                >
                                    About
                                </NuxtLink>
                            </div>

                            <div class="space-y-2">
                                <NuxtLink
                                    href="/solutions"
                                    class="-mx-3 block rounded-sm px-3 py-2 text-base font-bold leading-7 text-gray-900 hover:bg-green-50"
                                    @click="mobileMenuOpen = false"
                                >
                                    Solutions
                                </NuxtLink>
                            </div>

                            <div class="space-y-2">
                                <NuxtLink
                                    :to="`/contact?redirect=${route.fullPath}`"
                                    class="-mx-3 block rounded-sm px-3 py-2 text-base font-bold leading-7 text-gray-900 hover:bg-green-50"
                                    @click="mobileMenuOpen = false"
                                >
                                    Contact
                                </NuxtLink>
                            </div>
                            <div
                                v-if="showLogin"
                                class="space-y-2 border-t py-6"
                            >
                                <NuxtLink
                                    href="/login"
                                    class="-mx-3 block rounded-sm px-3 py-2 text-base font-bold leading-7 text-gray-900 hover:bg-green-50"
                                    @click="mobileMenuOpen = false"
                                >
                                    Log in
                                </NuxtLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="showUserMenu"
                    class="border-t border-gray-200 pb-3 pt-4"
                >
                    <div class="flex items-center px-4">
                        <div class="flex-shrink-0">
                            <Avatar
                                class="size-10"
                                :photo-url="civiqaUser?.photoUrl"
                                :name="civiqaUser?.fullName"
                                :no-tooltip="true"
                            />
                        </div>
                        <div class="ml-3">
                            <div class="text-base font-medium text-gray-800">
                                {{ civiqaUser!.fullName }}
                            </div>
                            <div class="text-sm font-medium text-gray-500">
                                {{ civiqaUser!.email }}
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 flex flex-col gap-2 px-6">
                        <NuxtLink
                            v-for="item in userNavigation"
                            :key="item.name"
                            as="a"
                            :href="item.href"
                            class="-mx-3 block rounded-sm px-3 py-2 text-base leading-7 text-gray-900 hover:bg-green-50"
                            @click="mobileMenuOpen = false"
                        >
                            {{ item.name }}
                        </NuxtLink>
                    </div>
                </div>
                <div
                    v-else
                    class="sticky bottom-0 grid grid-cols-2 divide-x divide-gray-900/5 bg-green-50 text-center"
                >
                    <NuxtLink
                        v-for="item in callsToAction"
                        :key="item.name"
                        :href="item.href as any"
                        class="py-4 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-100"
                        @click="mobileMenuOpen = false"
                    >
                        {{ item.name }}
                    </NuxtLink>
                </div>
            </DialogPanel>
        </Dialog>
    </header>
</template>
